module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "/portfolio"

  siteTitle: 'Andreas Sahle - @pixelmord', // Navigation and Site Title
  siteTitleAlt: 'Andreas Sahle', // Alternative Site title for SEO
  siteTitleShort: 'Andreas Sahle', // short_name for manifest
  siteHeadline:
    'My name is Andreas Sahle. I am a lead frontend engineer, tech lead and speaker based in Munich, Germany.', // Headline for schema.org JSONLD
  siteUrl: 'https://pixelmord.de', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  siteLogo: '/logo.png', // Used for SEO and manifest
  siteDescription:
    'My name is Andreas Sahle. I am a lead frontend engineer, tech lead and speaker based in Munich, Germany.',
  author: 'Andreas Sahle', // Author for schema.org JSONLD
  userTwitter: '@pixelmord', // Twitter Username
  googleAnalyticsID: 'UA-68678396-1',
  ogSiteName: 'AndreasSahle', // Facebook Site Name
  ogLanguage: 'en_US', // Facebook Language
};
